@keyframes moveUpDown {
  0% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(4px);
  }
}

.animate-moveUpDown {
  animation: moveUpDown 1s infinite alternate;
}